import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PetsIcon from '@mui/icons-material/Pets';
import Stack from '@mui/material/Stack';

export default function Footer() {
    return (
        <Box sx={{ flexGrow: 1, marginTop: "100px", height: "250px" }}>
        <Grid container spacing={2} sx={{textAlign: "left"}}>
        <Grid item xs={3}>
            <Box sx={{textAlign: "center"}}>
                <PetsIcon sx={{fontSize: "170px", opacity: "0.1"}}/>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
                <Stack direction="column" spacing={1} >
                <Typography variant='h6'>Our Store</Typography>
                <Typography variant='body1'>1017 XD Amsterdam</Typography>
                <Typography variant='body1'>Weteringschans 165 C</Typography>
                <Typography variant='body1'>+31 20 000 1122</Typography>
                <Typography variant='body1'>shop@besties.nl</Typography>

                </Stack>
            </Box>
          </Grid>
          <Grid item xs={3}>
          <Box>
                <Stack direction="column" spacing={1}>
                <Typography variant='h6'>Opening Hours</Typography>
                <Typography variant='body1'>Mon - Fri: 10am - 8pm</Typography>
                <Typography variant='body1'>Sat: 10am - 4pm​​</Typography>
                <Typography variant='body1'>Sun: 10am - 6pm</Typography>

                </Stack>
            </Box>
          </Grid>
          <Grid item xs={3}>
          <Box>
                <Stack direction="column" spacing={1}>
                <Typography variant='h6'>Credits</Typography>
                <Typography variant='body1'>Images by:</Typography>
                <Typography variant='body1'><a href="https://unsplash.com/@karsten116?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Karsten Winegeart</a></Typography>
                <Typography variant='body1'><a href="https://unsplash.com/photos/7vhqnO-sT88?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">Unsplash</a></Typography>
                </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
}