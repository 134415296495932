import React from "react";
import "./App.css";

import LoginForm from "./components/userForm/LogInForm";
import NavBar from "./components/navBar/NavBar";
import Home from "./pages/Home";
import Footer from "./components/footer/Footer";

function App() {
 
  return (
      <div className="App">
        <NavBar/>
        <Home/>
        <Footer/>
      </div>
    )
}

export default App;
