import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import "./Home.css"

import { useState, useEffect } from "react";

import axios from "axios";
import { Product } from "../../../common/types/types"
import ProductList from '../components/products/ProductList';


export default function Home() {
  const [products, setProducts] = useState<Product[]>([])

  const productsUrl = "https://besties-be.onrender.com/products";

  function getProductsData() {
    axios.get(productsUrl).then((response) => response.data).then((data) => setProducts(data));
  }

  useEffect(() => {
    getProductsData();
  }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={6}>
            <Box sx={{paddingTop: "15%", paddingLeft: "30px", textAlign: "left"}}>
            <Typography variant="h1" sx={{fontFamily: "Lexend Zetta", letterSpacing: '-10px'}}>
                <span style={{fontWeight: "100"}}>the </span>Street<br/> <span style={{fontWeight: "100"}}>is my </span>Runway
            </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className='imageHome'>
            </Box>
          </Grid>
        </Grid>
        <Box  sx={{ display: 'flex', flexFlow: "row wrap"}}>
        {
          products.map((product) => (
            <ProductList key={product.id} product={product}/>
          ))
        }
        </Box>
      </Box>
    )
}