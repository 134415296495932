import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { User } from '../../../../common/types/types';

type U = {
  userLoggedIn: User
}

export default function UserInformation({userLoggedIn}: U) {
  return (
    <Stack direction="row" spacing={2}>
      <Typography >
          HUMAN: {userLoggedIn.name}
      </Typography>
      <Divider orientation='vertical' />
      <Typography >
          BESTIE: {userLoggedIn.bestie}
      </Typography>
    </Stack>
  )
}
