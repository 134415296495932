
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import PetsIcon from '@mui/icons-material/Pets';

import UserInformation from '../userInformation/UserInformation';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';


import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LoginForm from '../userForm/LogInForm';
import Stack from "@mui/material/Stack"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { User } from "../../../../common/types/types"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function NavBar() {

    const [userId, setUserId] = useState(0);

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const [userLoggedIn, setUserLoggedIn] = useState<User>({id: 0, email: "", password: "", name: "", bestie: ""});

    const usersUrl = `https://besties-be.onrender.com/users/${userId}`;

    function getUsersDetails() {
        axios.get(usersUrl).then((response) => response.data).then((data) => setUserLoggedIn(data));
      }
    
      useEffect(() => {
        getUsersDetails();
      }, [userId]);

    return (
        <AppBar position="sticky" sx={{backgroundColor: "#000", boxShadow: "none", minHeight: "80px", justifyContent: "center", zIndex: "1999"}}>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Plaster',
                fontWeight: 700,
                fontSize: "3rem",
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                BESTIES
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                >
                <MenuIcon />
                </IconButton>
                <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
                >
                    <MenuItem onClick={handleClickOpen}>
                    <Typography textAlign="center">LetMeIn</Typography>
                    </MenuItem>
                    <MenuItem >
                    <Typography textAlign="center">WoofList</Typography>
                    </MenuItem>
                    <MenuItem >
                    <Typography textAlign="center">Human, Pay!</Typography>
                    </MenuItem>
                </Menu>
            </Box>
            <PetsIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, color: "#eea62a" }} />
            <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            >
                BESTIES
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mr: 5, justifyContent: "flex-end" }}>
                {
                    userLoggedIn.id !== undefined ? <UserInformation userLoggedIn={userLoggedIn}/> : null
                }
            </Box>

            <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>
                        <PetsIcon />
                    </Avatar>
                </IconButton>
                <Menu
                sx={{ mt: '60px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                <MenuItem onClick={handleClickOpen}>
                    <Typography textAlign="center">LetMeIn</Typography>
                    </MenuItem>
                    <MenuItem >
                    <Typography textAlign="center">WoofList</Typography>
                    </MenuItem>
                    <MenuItem >
                    <Typography textAlign="center">Human, Pay!</Typography>
                    </MenuItem>
                </Menu>
            </Box>
            </Toolbar>
        </Container>

        <Dialog
         fullScreen
         open={open}
         onClose={handleClose}
         TransitionComponent={Transition}
         sx={{marginTop: "80px"}}
       >
         <Box sx={{backgroundColor: "black", height: "100vh", display: "flex", justifyContent: "center", textAlign: "center" }}>
        <Stack direction="column" spacing={2}>
        <LoginForm handleClose={handleClose} handleCloseUserMenu={handleCloseUserMenu} setUserId={setUserId}/>
         <Button autoFocus onClick={handleClose}>
         <HighlightOffIcon sx={{color: "white", fontSize: 70 }}/>
        </Button>
        </Stack>
         </Box>
       </Dialog>
        </AppBar>
  );
}