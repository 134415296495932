import React from 'react'
import { Button, TextField } from '@mui/material'
import {Formik , Form} from 'formik'
import * as Yup from 'yup';
import { useState, useEffect } from "react";
import axios from 'axios';
import { User } from "../../../../common/types/types"
import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack"
import "./LoginForm.css"

import Input from '@mui/material/Input';

type U = {
  handleClose: Function;
  setUserId: Function;
  handleCloseUserMenu: Function
}

export default function LoginForm({handleClose, setUserId, handleCloseUserMenu}: U) {

  const [users, setUsers] = useState<User[]>([])

  const usersUrl = "https://besties-be.onrender.com/users";

  function getUsersData() {
    axios.get(usersUrl).then((response) => response.data).then((data) => setUsers(data));
  }

  useEffect(() => {
    getUsersData();
  }, []);

    type InitialValues = {
        email: string,
        password: string
      }

      const initialValues: InitialValues = {
        email: "", 
        password: ""
    }
    const FormSchema = Yup.object().shape({
        email: Yup.string().email('Try: dorothy@gmail.com').required('Please enter your e-mail address'), 
        password: Yup.string()
        .required('Please enter your password')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
          "Try: MySecretPass2"
        ),
    })
    const doSomething = (values : InitialValues) => {
      const emailInput = users.find(user => user.email === values.email);
      const passwordInput = users.find(user => user.password === values.password);
      if (emailInput && passwordInput) {
        handleClose();
        handleCloseUserMenu();
        setUserId(emailInput.id);
      } else {
        alert("Information incorrect!");
      }
    }
    return (
        <Box sx={{ bgcolor: 'black', paddingTop: "50px", width: "600px", height: "60vh", justifyContent: "center"}}>
                  <Formik
        initialValues={initialValues}
        validationSchema = {FormSchema}
        onSubmit = {doSomething}
        >
           {({errors, touched, handleChange}) => {
            return  <Form className='formRoot'>
        <Stack direction="column" spacing={5}>
            <div className="form-field">
                <Input sx={{width: "50%"}} placeholder="shaggy@gmail.com"  name='email' onChange = {handleChange}/>
                {errors.email && touched.email ? (
                  <div className='error-message'> {errors.email}</div>  
                ): null}
            </div>
            <div className="form-field">
                <Input type="password" sx={{width: "50%"}} placeholder="MySecretPass3"  name='password' onChange = {handleChange}/>
                {errors.password && touched.password ? (
                  <div className='error-message'> {errors.password}</div>  
                ): null}
            </div>
            <Button type="submit" sx={{color: "white", fontSize: 22 }}>Enter</Button>
            </Stack>
        </Form>
           }} 
        </Formik>
        </Box>
    )
}